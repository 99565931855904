
export default function Answer(props) {

  const classes = props.isActive ? "question__choice question__choice--selected" : "question__choice";

  return (
    <button
      className={classes}
      onClick={() => {
        props.updateAnswers(props.answer);
      }}
    >
      {props.answer.title}
    </button>
  );
}

import {useGame} from 'contexts/GameContext';
import { useEffect, useState } from "react";
import Header from 'components/Header';
import { toast } from "react-toastify";
import _ from "lodash";

import {
  SendResult,
} from "../../utils/api";

export default function Results() {
  const [time, setTime] = useState("00:00:00");
  const [showLastScreen, setShowLastScreen] = useState(false);

  const {
    totalQuestions,
    startTime,
    endTime,
    score,
  } = useGame();

  const convertTime = (ms) => {
    const second = Math.floor((ms / 1000) % 60)
      .toString()
      .padStart(2, "0");
    const minute = Math.floor((ms / 1000 / 60))
      .toString()
      .padStart(2, "0");
    setTime(
      minute + ":" + second
    );
  };

  const saveScore = async () => {
    //GET local user
    const getLocalUser = localStorage.getItem("user");
    const getLocalProgess = localStorage.getItem("progress");

    if (getLocalUser && getLocalProgess) {
      let user = JSON.parse(getLocalUser);
      let progress = JSON.parse(getLocalProgess);

      user.end_time = endTime;
      user.score = progress.score;
      user.time_to_completion = time;


      // Send Result
      await SendResult(user.id, {
        ..._.omit(user, ["slug", "id"]),
      });
      toast.success("Votre score a été enregistré avec succès !");

      //localStorage.clear();
      //window.location.reload();

    }
  };

  useEffect(() => {
    var timediff = (new Date(endTime) - new Date(startTime));
    convertTime(timediff);
  });

  const callback = () => {
    setShowLastScreen(true)
    saveScore()
  }

  const getFinalText = (score) => {
    if(score < 4) {
      return "Aie, Aie, Aie … Code de la route et règles de circulation ne sont pas ton point fort. Ça tombe bien on est là pour t’aider. Tu vas pouvoir t’entrainer."
    } else if (score < 9) {
      return "Quelques lacunes, rien de grave ! Des ressources nécessaires sont là !"
    } else if (score < 14) {
      return "Pas mal tu es sensibilisé au code de la route et au respect des règles."
    } else {
      return "Félicitations ! Le code de bonne conduite n’a plus de secret pour toi ! "
    }
  }

  const rejouer = () => {
    localStorage.clear();
    window.location.reload();
  }

  return (
    <>
      <Header
          showLogo={false}
          objectif="Objectif sécurité"
          title="Quiz sécurité routière"
          withBg={true}
        />
      <main className="layout layout--orange">
        { showLastScreen ?
          <>
            <div className="layout__results">
              <div className="results">
                  <div className="results__text">
                    {getFinalText(score)}<br/><br/>

                    Curieux d’en apprendre davantage ?
                  </div>
                </div>
            </div>
            <div className="layout__btn layout__btn--margin-bot">
              <a href="https://www.securite-routiere.gouv.fr/les-medias/nos-quiz/je-repasse-le-code" target="_blank" rel="noreferrer" className="btn">Entraîne-toi ici !</a>
              <button className="btn" onClick={()=>rejouer()}>Terminer</button>
            </div>
          </>
        :
          <>
            <div className="layout__results">

                <div className="results">
                  <div className="results__title">
                    Merci pour ta participation
                  </div>

                  <div className="results__wrap">
                    <div className="results__correct">
                      <div className="results__correct-count">
                        {score}/<span className="results__correct-count--smaller">{totalQuestions}</span>
                      </div>
                      <div className="results__correct-label">
                        Bonnes réponses
                      </div>
                    </div>
                    <div className="results__time">
                      <div className="results__timebig">{time}</div>
                      <div className="results__timelabel">Minutes</div>
                    </div>
                  </div>
                </div>
            </div>

            <div className="layout__btn layout__btn--margin-bot">
              <button className="btn" onClick={()=>callback()}>Suivant</ button>
            </div>
          </>
        }


        <div className="layout__logo-bg">
          <img
            src="/eckes-granini-logo.svg"
            className=""
            alt=""
          />
        </div>

      </main>
    </>
  );
}

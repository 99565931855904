import { useState, useEffect } from "react";

import Answer from 'components/Answer';


export default function Question(props) {
  const [selectedAnswers, setSelectedAnswers] = useState([]);


  const resetSelectedAnswers = () => {
    setSelectedAnswers([])
  }
  useEffect(resetSelectedAnswers, [props.title])

  const updateAnswers = (answer) => {

    let answers = [...selectedAnswers];
    let foundIndex = null;

    answers.forEach((item, index) => {
      if (item.title === answer.title) {
        foundIndex = index;
      }
    });

    if (foundIndex !== null) {
      answers.splice(foundIndex, 1);
    } else {
      answers.push(answer);
    }

    setSelectedAnswers(answers);

  };

  const checkActive = (title) => {
    let isActive = false;

    selectedAnswers.forEach((item) => {
      if (item.title === title) {
        isActive = true;
      }
    });
    return isActive;
  };

  return (
    <>
      { props.showExplanation ?

        <div className="layout__center-path">
          <div className="explanation">

          { props.answerIsCorrect ?
            <>
              <img src="/smiley.svg" alt="" className="explanation__img" />
              <div className="explanation__result">
                Bonne réponse
              </div>
            </>
            :
            <>
              <img src="/saddey.svg" alt="" className="explanation__img" />
              <div className="explanation__result explanation__result--wrong">
                Mauvaise réponse
              </div>
            </>
          }

            <div className="explanation__title">Explication</div>
            <div className="explanation__text">
              {props.explanation}
            </div>
            <div className="explanation__btn">
              <button className="btn btn--orange" onClick={()=>{props.next()}}>Suivant</button>
            </div>
          </div>
        </div>
      :
        <div className="question">
          <h1 className="question__title">{props.title}</h1>

          <div className="question__wrap">
            {props.image.data !== undefined && props.image.data !== null ?

                <img src={props.image.data.attributes.url} alt="" className="question__img" />
              :
                ""
            }

            <div className="question__choice-wrap">
              {props.answers.map((answer, i) => {
                return (
                  <Answer
                    key={i}
                    answer={answer}
                    isActive={checkActive(answer.title)}
                    updateAnswers={updateAnswers}
                  />
                );
              })}
            </div>

          </div>
          <div className="question__btn">
            <button className="btn" onClick={()=>{props.callback(selectedAnswers)}}>Valider</button>
          </div>
        </div>
      }
    </>
  );
}

import { createContext, useState, useContext, useEffect} from 'react';

const GameContext = createContext();

const GameContextProvider =  function ({ children }) {
  // Initialize state
  const [wasLoaded, setWasLoaded] = useState(false)
  const [score, setScore] = useState(0)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [totalQuestions, setTotalQuestions] = useState(0)
  const [startTime, setStartTime] = useState(0)
  const [endTime, setEndTime] = useState(0)
  const [isGameOver, setIsGameOver] = useState(false)

  const loadProgress = () => {
    const progress = localStorage.getItem("progress");

    if(progress != null) {
      const progressObject = JSON.parse(progress);

      setScore(progressObject.score)
      setStartTime(progressObject.startTime)
      setEndTime(progressObject.endTime)
      setCurrentQuestion(progressObject.currentQuestion)
      setTotalQuestions(progressObject.totalQuestions)
      setIsGameOver(progressObject.isGameOver)
    }
  }

  if( !wasLoaded) {
    loadProgress()
    setWasLoaded(true);
  }

  const saveProgress = () => {
    const progress = {
      score: score,
      currentQuestion: currentQuestion,
      totalQuestions: totalQuestions,
      isGameOver: isGameOver,
      startTime: startTime,
      endTime: endTime,
    }

    localStorage?.setItem(
      "progress",
      JSON.stringify(progress)
    );
  }

  useEffect(saveProgress, [score, currentQuestion, isGameOver, endTime])


  const provided = {
    wasLoaded, setWasLoaded,
    score, setScore,
    currentQuestion, setCurrentQuestion,
    totalQuestions, setTotalQuestions,
    startTime, setStartTime,
    endTime, setEndTime,
    isGameOver, setIsGameOver,
  }

  return (
    <GameContext.Provider value={provided}>
      {children}
    </GameContext.Provider>
  );
};

export default GameContextProvider;


// Create a hook to use the APIContext, this is a Kent C. Dodds pattern
export function useGame() {
  const context = useContext(GameContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
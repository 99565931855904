import Header from 'components/Header';
import { useNavigate } from "react-router-dom";


export default function Intro() {

  const navigate = useNavigate();

  const goToStart = () => {
    navigate("/start")
  }

  return (
    <>
      <Header
        withBg={true}
        showLogo={true}
        objectif="Objectif sécurité"
        title="Quiz sécurité routière"
      />
      <main className="layout">
        <div className="layout__wrapper">

          <div className="layout__intro-img-wrap">

            <img
              src="/campagne-sec1.jpg"
              className="layout__intro-img"
              alt=""
            />
            <img
              src="/campagne-sec2.jpg"
              className="layout__intro-img"
              alt=""
            />
            <img
              src="/campagne-sec3.jpg"
              className="layout__intro-img"
              alt=""
            />
            <img
              src="/campagne-sec4.jpg"
              className="layout__intro-img"
              alt=""
            />

          </div>

          <div className="layout__btn">
            <button onClick={()=>{goToStart()}} className="btn">Suivant</button>
          </div>
        </div>

        <div className="layout__bottom-gray"></div>
        <div className="layout__color-band"></div>
      </main>
    </>
  );
}

import axios from "utils/axios";

export const PostParticipant = (data) => {

  return axios.post("/participants", { data });
};

export const GetQuestions = () => {
  return axios.get(
    `/questions?sort=rank&populate=*`
  );
};

export const CheckParticipantSlug = (slug) => {
  return axios.get(`/participants/?filters[slug][$eq]=${slug}`);
};

export const SendResult = (id, data) => {
  return axios.put(`/participants/${id}`, { data });
};

import Header from 'components/Header';
import Question from 'components/Question';

import {useGame} from 'contexts/GameContext';
import {useEffect, useState} from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";


export default function Quiz() {

  const [answerIsCorrect, setAnswerIsCorrect] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);
  const navigate = useNavigate();

  const {
    currentQuestion,
    setCurrentQuestion,
    totalQuestions,
    setTotalQuestions,
    startTime,
    setStartTime,
    isGameOver,
    setIsGameOver,
    score,
    setScore,
    setEndTime,
  } = useGame();

  let quizQuestionsJson = localStorage.getItem("questions");
  let quizQuestions = JSON.parse(quizQuestionsJson);

  let participantJson = localStorage.getItem("user");
  let participant = JSON.parse(participantJson);

  let activeQuestion = quizQuestions[currentQuestion];
  let answers = activeQuestion.answers;


  useEffect(() => {
    setTotalQuestions(quizQuestions.length);
    setStartTime(participant.start_time);
  });

  const answerQuestion = (selectedAnswers) => {
    const goodAnswers = answers.filter((item) => item.is_correct === true)
    let correct = false;

    if (goodAnswers.length === 0) {
      toast.error("Aucune bonne réponse n'a été définie");
      return
    }

    if (selectedAnswers.length < 1) {
      toast.error("Vous devez sélectionner au moins une réponse");
      return
    }

    const correctSelectedAnswers = selectedAnswers.filter((item) => item.is_correct === true)

    if (selectedAnswers.length === correctSelectedAnswers.length && correctSelectedAnswers.length === goodAnswers.length) {
      correct = true;
    }

    if (correct) {
      setScore(score + 1)
    }

    window.scrollTo(0, 0);
    setAnswerIsCorrect(correct);
    setShowExplanation(true);
  }

  const next = () => {
    window.scrollTo(0, 0);
    setAnswerIsCorrect(false);
    setShowExplanation(false);

    if (currentQuestion < totalQuestions - 1) {
      setCurrentQuestion(currentQuestion + 1)
    } else {
      // game is over, show result page
      setIsGameOver(true)
    }
  }


  const redirectIfGameOver = () => {
    if(isGameOver) {
      setEndTime(new Date());
      navigate("/results")
    }
  }
  useEffect(redirectIfGameOver, [navigate, isGameOver, setEndTime])


  return (
    <>
      <Header
          showLogo={true}
          objectif="Objectif sécurité"
          title="Quiz sécurité routière"
          currentQuestion={currentQuestion+1}
          totalQuestions={totalQuestions}
          startTime={startTime}
          withBg={true}
          extraClasses="header--quiz"
        />
      <main className="layout layout--gray layout--question-bg">
        <div className="layout__wrapper">

          <Question
            title={activeQuestion.title}
            image={activeQuestion.image}
            explanation={activeQuestion.explanation}
            answers={activeQuestion.answers}
            showExplanation= {showExplanation}
            answerIsCorrect= {answerIsCorrect}
            callback= {answerQuestion}
            next={next}
          />
        </div>
      </main>
    </>
  );
}

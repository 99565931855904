import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from "react-router-dom";

import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Intro from "./pages/Intro";
import Start from "./pages/Start";
import Quiz from "./pages/Quiz";
import Results from "./pages/Results";


const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Home />,
      errorElement: <NotFound />,
      loader: () => {
        const localUser = localStorage.getItem("user");
        if (localUser) {
          // go to last step
          return redirect("/quiz");
        }
        return true;
      },
    },
    {
      path: "/intro",
      element: <Intro />,
      errorElement: <NotFound />,
      loader: () => {
        const localUser = localStorage.getItem("user");
        if (localUser) {
          // go to last step
          return redirect("/quiz");
        }
        return true;
      },
    },
    {
      path: "/start",
      element: <Start />,
      errorElement: <NotFound />,
      loader: () => {
        const localUser = localStorage.getItem("user");
        if (localUser) {
          // go to last step
          return redirect("/quiz");
        }
        return true;
      },
    },
    {
      path: "/quiz",
      element: <Quiz />,
      errorElement: <NotFound />,
      loader: () => {
        const localUser = localStorage.getItem("user");
        if (!localUser) {
          return redirect("/start"); // go home
        }
        return true;
      },
    },
    {
      path: "/results",
      element: <Results />,
      errorElement: <NotFound />,
      loader: () => {
        const localUser = localStorage.getItem("user");
        if (!localUser) {
          return redirect("/"); // go home
        }
        return true;
      },
    },
    // {
    //   path: "/presentation",
    //   element: <PresentationPage />,
    //   errorElement: <NotFound />,
    //   loader: () => {
    //     localStorage.setItem("current-page", "presentation");
    //     return true;
    //   },
    // },
    // {
    //   path: "/game",
    //   element: <GamePage />,
    //   errorElement: <NotFound />,
    //   loader: () => {
    //     const localUser = localStorage.getItem("user");
    //     if (!localUser) {
    //       // go to home
    //       return redirect("/"); // go home
    //     }
    //     return true;
    //   },
    // },
    // {
    //   path: "/result",
    //   element: <ResultPage />,
    //   errorElement: <NotFound />,
    //   loader: () => {
    //     const localUser = localStorage.getItem("user");
    //     if (!localUser) {
    //       // go to home
    //       return redirect("/"); // go home
    //     }
    //     return true;
    //   },
    // },
  ],
  { basename: "/" }
);

const Router = () => <RouterProvider router={router} />;

export default Router;
